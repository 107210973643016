import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import IconTeaser from '../../components/IconTeaser'
import NavigationLink from '../../components/NavigationLink'
import IndividualContactSection from '../../components/IndividualContactSection'
import Image from '../../components/Image'
import Card from '../../components/Card'
import CardDeck from '../../components/CardDeck'

const Page = () => {
  return (
    <React.Fragment>
      <Seo title="Referenz: One Data GmbH"
           description="Erfahren Sie mehr über unsere Zusammenarbeit mit der One Data GmbH an ihrer Open End-to-End Data-Product-Plattform ONE DATA und deren Überführung in die Welt der Microservices."
      />

      <BigTitle
        title="Big Data meets Cloud"
        imageRelativePath={'Titelbild_Architektur.jpg'}
        backgroundColor="#fff"
        className="mask-black"
        textInverted={true}
        menuInverted={true}
        logoInverted={true}
      >
        Case Study - One Data
      </BigTitle>
      <DefaultSection className="pt-5 pt-lg-0">
        <Article>
          <p>
            Als Firma hinter der Open End-to-End Data-Product-Plattform ONE DATA befindet sich die One Data GmbH in
            einem
            stetig wachsenden und dynamischen Umfeld. Der hohe Konkurrenzdruck insbesondere durch neue Wettbewerber
            erfordert
            es daher, schnell und qualitativ hochwertig Innovationen stetig auszuliefern und Kundenwünsche erfüllen zu
            können.
          </p>
          <p>
            Voraussetzung für den Erfolg ist dabei eine leistungsfähige und skalierbare Architektur, die es erlaubt,
            nicht
            nur heutige, sondern auch zukünftige Anforderungen bestmöglich zu erfüllen. Auf Basis der seit 2013
            entwickelten,
            erfolgreichen flexiblen ONE DATA Plattform war es die Aufgabe von BUSCHMAIS, eine moderne Architektur unter
            Berücksichtigung individueller Geschäftsziele sowie fachlicher und technischer Constraints zu entwerfen und
            den
            Prozess der Modernisierung zu planen und zu begleiten.
          </p>
        </Article>
        <CardDeck className="mt-5 mb-3" cardsPerRow={2}>
          <Card>
            <div className="text-center text-md-left">
              <h2>Technologien</h2>
              <p className="mt-5">
                jQAssistant, AISE-Prozess, Microservices
              </p>
            </div>
          </Card>
          <Card>
            <div className="text-center text-md-left">
              <h2>Branche</h2>
              <p className="mt-5">
                Data Science
              </p>
            </div>
          </Card>
        </CardDeck>
        <Article>
          <h2>Die Herausforderung: Time-to-Market und Cloud-readiness</h2>
          <p>
            Die ONE DATA Plattform entwickelt sich stetig weiter, um neue Anwendungsfälle zu erschließen und
            schlussendlich
            dem Kunden zu jeder Zeit einen Mehrwert zu bieten. Dabei ist eine geringe Time-to-Market besonders wichtig,
            um sich gegen Konkurrenten durchsetzen und Kunden langfristig binden zu können. Eine wichtige Voraussetzung
            zur Optimierung dieser stellt dabei die gleichzeitige Entwicklung mehrerer Features verschiedener
            Fachlichkeiten
            dar. Dies ist in dem aktuellen monolithischen und technisch-orientierten System jedoch nicht optimal
            möglich,
            da sich eine Änderung schnell über das gesamte System verteilt. Auch das separate Ausrollen von Änderungen
            ist
            dadurch nur eingeschränkt möglich. Als erste zu optimierende Größe wurde daher mit BUSCHMAIS die
            Time-to-Market
            definiert.
          </p>
          <p>
            <Image relativePath={'Case-Study-One-Data-Abb1.png'}
                   alt="Entwicklung der Aufwände für die Feature-Entwicklung in Abhängigkeit der umgesetzten Architektur über die Projektlaufzeit"
                   caption="Entwicklung der Aufwände für die Feature-Entwicklung in Abhängigkeit der umgesetzten Architektur über die Projektlaufzeit" />
          </p>
          <p>
            Um neue Kunden zu binden und insbesondere den Betrieb und die Skalierung der Plattform zu vereinfachen, soll
            langfristig die Bereitstellung der ONE DATA Plattform nicht nur On-Premises auf Kundenhardware erfolgen. Um
            langfristig optimal von den Möglichkeiten der Cloud profitieren zu können, muss die ONE DATA Plattform daher
            hohe Anforderungen unter anderem in den Bereichen Skalierbarkeit und Resilienz erfüllen. Als zweite zu
            optimierende Größe wurde daher die Nutzbarkeit von Cloud-Features definiert, zu dessen Prüfung die
            Entkopplung
            technischer und fachlicher Aspekte genutzt wird.
          </p>
        </Article>
      </DefaultSection>
      <DefaultSection className="bg-level-1">
        <IconTeaser imageRelativePath={'Logo_One-Data.png'} iconImageAlt="Logo One Data">
          <p className="text-black-50">
            <NavigationLink to="https://onedata.de"
                            ariaLabel="Link zur Webseite der One Data GmbH" asTextLink="true">One
              Data</NavigationLink> strebt danach, Daten zielgenau bei Produktentwicklung, Marktprognosen, Forecasts
            und Co. einzusetzen
            und Unternehmen somit in die Lage zu versetzen, beliebige Daten schnell in nachhaltigen Mehrwert
            umzuwandeln.
            Mit der eigens entwickelten Open End-to-End Data-Product-Plattform ONE DATA lassen sich Data Science und der
            Weg zum feingeschliffenen Datenprodukt stark beschleunigen. Unsere Vision ist es, die Welt durch Wert und
            Wahrheit
            von Daten zu erleuchten.
          </p>
        </IconTeaser>
      </DefaultSection>
      <DefaultSection>
        <Article>
          <h2>Das Ziel: Eine leistungsfähige, zukunftssichere Architektur</h2>
          <p>
            Beginnend mit einem mehrtägigen Intensivworkshop hat BUSCHMAIS eine Analyse der bestehenden Architektur
            vorgenommen.
            Anhand der ISO 25010 (Software-Engineering - Qualitätskriterien und Bewertung von Softwareprodukten (SQuaRE)
            - Qualitätsmodell und Leitlinien)
            wurde der Ist- und Soll-Zustand dokumentiert. Besonders die Arbeit mit der ISO 25010 half dabei,
            Schwachstellen der
            aktuellen Architektur in Hinblick auf die definierten Geschäftsziele sichtbar zu machen.
          </p>
          <p>
            <Image relativePath={'Case-Study-One-Data-Abb2.png'}
                   alt="Vergleich des Ist- und Soll-Erfüllungsgrades relevanter Softwarequalitäts-Eigenschaften nach ISO 25010 und dem entstehenden Risiko bei Nichterfüllung"
                   caption="Vergleich des Ist- und Soll-Erfüllungsgrades relevanter Softwarequalitäts-Eigenschaften nach ISO 25010 und dem entstehenden Risiko bei Nichterfüllung" />
          </p>
          <p>Auf dieser Basis konnte anschließend die langfristige Zielarchitektur definiert werden. Hierbei zerfällt
            die ONE DATA Plattform
            in fachlich motivierte Microservices, um die separate Entwicklung und Veröffentlichung einzelner
            Fachlichkeiten zu ermöglichen,
            sowie das Geschäftsziel der geringeren Time-to-Market zu erreichen. Je nach Anwendungsfall kommunizieren die
            Services
            synchron oder asynchron Remote, um eine maximale Entkopplung und damit eine höhere Skalierbarkeit und
            Resilienz zu erreichen.
            Der Betrieb der Services ist mittels Kubernetes gelöst, um eine flexible und leistungsstarke Anpassung an
            Last und zukünftige
            Anwendungsfälle zu ermöglichen. Die Wahl einer Microservices-orientierten Architektur und der Nutzung von
            Kubernetes ist optimal,
            um die Vorteile der Cloud-Infrastruktur optimal zu nutzen und damit das zweite Geschäftsziel zu
            erreichen.</p>
          <h2>Der Lösungsweg: Modernisierung parallel zur Produktweiterentwicklung</h2>
          <p>
            Modernisierungsmaßnahmen, welche die Architektur eines Software-Systems betreffen, sind aufwändige
            Unterfangen.
            Sie betreffen Querschnittsaspekte des Systems und sind damit insbesondere in monolithisch aufgebauten, stark
            gekoppelten Systemen schwierig umzusetzen. Als zusätzliche Komplexitätsebene ergibt sich, dass aufgrund von
            architektureller Arbeit nicht die Umsetzung neuer Funktionalitäten ausgesetzt beziehungsweise zu stark
            behindert werden darf.
          </p>
          <p>
            <Image relativePath={'Case-Study-One-Data-Abb3.png'}
                   alt="Die vier Phasen des iterativen AISE-Prozesses"
                   caption="Die vier Phasen des iterativen AISE-Prozesses" />
          </p>
          <p>
            Unter Berücksichtigung der projektorganisatorischen Rahmenbedingungen wurde ein Migrationsplan inklusive der
            Umsetzung eines Pilotprojekts auf Basis des von BUSCHMAIS entwickelten AISE-Prozesses aufgestellt.
            Hauptaugenmerk
            war dabei die Vereinbarkeit von Modernisierung und Weiterentwicklung sowie der sukzessive Aufbau von Wissen
            im Team,
            zu dessen Erfüllung die Umsetzung eines Pilotprojekts für einen zukünftigen Service beiträgt.
          </p>
          <h2>Die Realisierung: Sicher in die Zukunft mit Software Analytics und aktivem Monitoring</h2>
          <p>
            Wichtig bei derartig weitreichenden Modernisierungsmaßnahmen ist die umfangreiche Vorbereitung, wie im
            AISE-Prozess
            skizziert. Dabei setzt BUSCHMAIS auf die Möglicheiten von Software Analytics gepaart mit dem präzisen und
            effizienten
            Open Source Tool jQAssistant. Durch den Einsatz dieses primär durch BUSCHMAIS entwickelten Tools erfolgt im
            Anschluss
            das kontinuierliche Monitoring der Fortschritte.
          </p>
          <p>jQAssistant lässt sich nahtlos in den AISE-Prozess integrieren und unterstützt bei der initialen und
            kontinuierlichen
            Analyse, der Definition der konkreten Migrationsschritte, sowie der Dokumentation und Absicherung
            geschaffener Strukturen.</p>
          <p>Mit der Unterstützung von BUSCHMAIS erfolgte das Set-Up von jQAssistant im Projekt, womit One Data bereits
            erste Analyseergebnisse
            über die Identifizierbarkeit von und Kopplung zwischen fachlichen Domänen vorliegen hatte. Die mit BUSCHMAIS
            definierten Metriken, u. a.
            dem Kopplungsgrad fachlicher Domänen auf Code-Ebene, sowie Hot-Spot-Analysen orientieren sich dabei direkt
            an den zuvor festgelegten KPIs
            und folgen demnach den Geschäftszielen.</p>
          <p>
            <Image relativePath={'Case-Study-One-Data-Abb4.png'}
                   alt="Aufwand/Risiko mit/ohne Planung und Montioring mit jQA"
                   caption="Aufwand/Risiko mit/ohne Planung und Montioring mit jQA" />
          </p>
          <p>Auf Basis der initialen Bestandsaufnahme konnten weitere Analysen durchgeführt werden, um die notwendigen
            Schritte
            zur Separierung der Fachlichkeiten und damit zur Umsetzung des Pilotprojekts zu definieren. Dank jQAssistant
            sind
            dabei die durchgeführten Analysen und Metriken direkt im Projekt verfasst und erlauben die kontinuierliche
            Validierung
            und Überwachung der Fortschritte. Die geschaffene Transparenz über den aktuellen Stand macht sowohl die
            Arbeit für
            Entwickler als auch für Projektverantwortliche planbar und minimiert Risiken.</p>
          <h2>Fazit: A Match Made in Heaven</h2>
          <p>
            Umfangreiche Arbeiten an der Software-Architektur bergen immer Risiken. Mit der Hilfe von BUSCHMAIS und
            jQAssistant
            konnten diese im Rahmen der Beratung aufgezeigt und deren potentielle Folgen reduziert werden. Die
            durchgeführten
            Softwareanalysen helfen dabei, einen Überblick über die Anwendung zu erhalten, fachliche Domänen zu
            identifizieren
            und den Schnitt in Microservices optimal vorzubereiten. Das kontinuierliche Monitoring unterstützt dabei,
            Risiken direkt
            zu identifizieren, zu kommunizieren und passend zu reagieren.
          </p>
          <p>
            Es wurde das notwendige Wissen aufgebaut, um auch hinsichtlich weiterer fachlicher Domänen die
            Zielarchitektur
            umsetzen zu können.
          </p>
        </Article>
      </DefaultSection>
      <DefaultSection>
        <Article>
          <p>
            <NavigationLink to="/download/Case-Study_One-Data.pdf" asTextLink
                            ariaLabel="ONE DATA – Big Data meets Cloud bei One Data">Download
              (PDF)</NavigationLink>
            <br /><small className="text-muted">Stand: 2023</small>
          </p>
        </Article>
      </DefaultSection>
      <IndividualContactSection personKey={'stephan'} />
    </React.Fragment>
  )
}

export default Page
